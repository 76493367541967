<template>
  <div>
    <table v-if="recordings && recordings.length">
      <thead>
        <tr>
          <td>Respondent</td>
          <td v-if="!mobile">Calibration</td>
          <td v-if="!mobile">Eye tracking</td>
          <td v-if="!mobile">Emotions</td>
          <!-- <td v-if="!mobile">Clicks</td> -->
          <td v-if="!mobile">Date</td>
          <td class="text__center">Play</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="respondent in respondentsList" :key="respondent.ProjectContact"
          :class="{ not_synced: Math.min(respondent.CalibrationEyeTracker, respondent.IntegrityEmotions, respondent.IntegrityEmotionsRecognition, respondent.IntegrityEyeTracker) == -1 && Math.max(respondent.CalibrationEyeTracker, respondent.IntegrityEmotions, respondent.IntegrityEmotionsRecognition, respondent.IntegrityEyeTracker) != -1 }">
          <td class="flex__sc">
            <div class="mr__20">
              <img v-if="respondent.Photo" :src="respondent.Photo" alt="respondent Photo" />
              <img v-else src="@/assets/img/account-defult.jpg" alt="default respondent" />
            </div>
            <div>
              <div>Respondent</div>
              <div class="mt__5">{{ respondent.ProjectContact }}</div>
            </div>
          </td>
          <td v-if="!mobile" class="center">
            <ProgressBlock :params="{
      state: toString(respondent.CalibrationEyeTracker * 20),
    }" />
          </td>
          <td v-if="!mobile" class="center">
            <ProgressBlock :params="{ state: toString(respondent.IntegrityEyeTracker) }" />
          </td>
          <td v-if="!mobile" class="center">
            <ProgressBlock :params="{ state: toString(respondent.IntegrityEmotions) }" />
          </td>
          <!-- <td v-if="!mobile" class="text__center">
              {{respondent.Clicks}}
          </td>-->
          <td v-if="!mobile" class>
            <div>{{ respondent.AnswerDate }}</div>
            <div class="title__12 grey__210 mt__5">
              {{ respondent.AnswerTime }}
            </div>
          </td>
          <td>
            <div class="flex__cc">

              <a v-show="respondent.Status != 0" @click="openPlayer(respondent)" href="javascript:void(0)"
                class="text__center icon__video__play">
                <IconPlay />
              </a>
            </div>

          </td>
        </tr>
      </tbody>
    </table>
    <div v-else-if="recordings !== false" class="text__center">
      You have not collected any responses yet
      <br />Start the project to see the recordings here
    </div>
    <div v-if="recordings.length > pageSize" class="tfoot pagination flex__sc">
      <div class="mr__30 flex__sc">
        Rows per page
        <select v-model="pageSize" class="ml__6">
          <option value="10" selected>10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
        <img src="@/assets/img/icons/arr-triangle.svg" alt="icon" />
      </div>
      <div class="pagination__of mr__30">
        <b>{{ (currentPage - 1) * pageSize + 1 }}-{{
      Math.min(currentPage * pageSize, recordings.length)
    }}</b>
        of {{ recordings.length }}
      </div>
      <div class="pagination__arrows flex__sc">
        <button class="pagination__arrows__back flex__sc mr__30" @click="currentPage = 1" :disabled="currentPage < 2">
          <IconArrowRight />|
        </button>
        <button class="pagination__arrows__back flex__sc mr__30" @click="prevPage" :disabled="currentPage < 2">
          <IconArrowRight />
        </button>
        <button class="flex__sc mr__30" @click="nextPage" :disabled="currentPage * pageSize > recordings.length">
          <IconArrowRight />
        </button>
        <button class="flex__sc" @click="currentPage = ((recordings.length / pageSize) ^ 0) + 1"
          :disabled="currentPage * pageSize > recordings.length">
          <IconArrowRight />|
        </button>
      </div>
    </div>
    <div class="replay_overlay" v-if="replayer_visible">
      <div class="close_replay_overlay" @click="replayer_visible = false">
        +
      </div>
      <iframe class="replay_frame"
        :src="'https://app.uxreality.com/static/replay_ux/index.html?rid=' + replayer_resp.ProjectContact + '&qid=' + replayer_resp.QuestionId"></iframe>
    </div>
  </div>
</template>

<script>
import download from "../../lib/download";
export default {
  props: ["recordings"],
  data() {
    return {
      mobile: this.ismobile,
      currentPage: 1,
      pageSize: 10,
      replayer_visible: false,
      replayer_resp: null,
    };
  },
  computed: {
    respondentsList() {
      return this.recordings.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
  },
  watch: {
    pageSize() {
      this.currentPage = 1;
    },
  },
  methods: {
    openPlayer(respondent) {
      this.replayer_resp = respondent;
      this.replayer_visible = true;
    },
    toString(value, cf, max) {
      return ["low", "normal", "high"][Math.floor(value / 34)];
    },
    nextPage: function () {
      if (this.currentPage * this.pageSize < this.recordings.length) {
        this.currentPage++;
      }
    },
    prevPage: function () {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    handleDownload(url, name) {
      fetch(url)
        .then(function (resp) {
          return resp.blob();
        })
        .then(function (blob) {
          download(blob, name, "video/mp4");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  .flex__sc {
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }

  .icon__video__play {
    display: block;
  }

  .icon__video__play_internal {
    margin-left: 8px;
    position: relative;
  }

  .icon__video__play_internal::after {
    content: "B";
    font-weight: 600;
    position: absolute;
    font-size: 12px;
    left: 80%;
    bottom: 80%;
    color: #00bceb;
  }

  .loader__mini {
    width: 40px;
    height: 40px;
  }
}

.replay_overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.close_replay_overlay {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-size: 59px;
  line-height: 35px;
  rotate: 45deg;
  user-select: none;
  cursor: pointer;
  z-index: 1;
}

.replay_frame {
  position: absolute;
  top: 28px;
  left: 28px;
  height: calc(100% - 56px);
  width: calc(100% - 56px);
  border: none;
}

.not_synced {
  position: relative;
}

.not_synced::after {
  position: absolute;
  content: "Incomplete Synchronization";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .8);
  text-align: center;
  line-height: 94px;
  font-size: 19px;
  font-weight: bold;
  color: #b5b5b5;
}
</style>